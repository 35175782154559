import React from 'react'
import Page from '../components/Page'
import SEO from '../components/seo'
import Jobs from '../components/Jobs'

const JobsPage = () => (
  <Page>
    <SEO
      title="Opentrons Careers | Help Make Automation Open &amp; Accessible"
      description="Lab automation should be affordable, easy to use, and open to customization. The more people that have access to powerful technology, the better."
    />
    <Jobs />
  </Page>
)

export default JobsPage
