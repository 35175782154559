// @flow
import * as React from 'react'

import * as styles from './Jobs.module.css'

const HEADER = `Join Opentrons`
const SUB_HEADER = `Opentrons makes lab robots for biologists. Our software enables
            users to easily design, test, and execute biology experiments, then
            share automated experiment workflows.`
export default function Jobs() {
  return (
    <>
      <div className={styles.job_container}>
        <section className={styles.jobs}>
          <h2 className={styles.h2}>{HEADER}</h2>
          <h5 className={styles.h5}>{SUB_HEADER}</h5>
        </section>
      </div>
      <div className={styles.bg_color}>
        <div className={styles.container}>
          <div
            className="jv-careersite"
            data-careersite="opentrons"
            data-force-redirect
            // align="center"
          >
            <iframe
              className={styles.embed_iframe}
              id="inlineframe"
              name="HRM Direct Career Site iFrame"
              sandbox="allow-top-navigation allow-scripts allow-forms allow-popups allow-same-origin"
              src="https://jobs.jobvite.com/opentrons"
              frameBorder="0"
              title="Career Site"
            />
          </div>
          <script
            type="text/javascript"
            src="https://jobs.jobvite.com/__assets__/scripts/careersite/public/iframe.js"
          />
        </div>
      </div>
    </>
  )
}
